import {useDialogContext} from "../App";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {tenantService} from "../../services/tenantService";
import PageWrapper from "../components/PageWrapper";
import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import Reservations from "../reservations/Reservations";
import Visits from "../visits/Visits";
import Spinner from "../shared/Spinner";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {formatCurrency} from "../shared/utils";
import {toCapitalize} from "../shared/utils";
import defaultAvatar from "../../assets/images/avatar.png";
import defaultPhoto from "../../assets/images/backoffice_bg.jpg";
import cogoToast from "cogo-toast";
import {dataService} from "../../services/dataService";
import Select from "react-select";
import DatePicker from "react-datepicker";
import jsonDiff from 'json-diff';
import {propertyService} from "../../services/propertyService";
import {authService, useAuth} from "../../services/authService";
import ReactPlayer from 'react-player';
import play from "../../assets/images/play.svg";


const TenantDetails = () => {

    const {t} = useTranslation();
    let {user} = useAuth();
    const {setModal} = useDialogContext();

    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);

    let {tenant_id} = useParams();

    const [tenant, setTenant] = useState(null)
    const [editedTenant, setEditedTenant] = useState(null);

    const [universities, setUniversities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [regions, setRegions] = useState([]);
    const [dialCodes, setDialCodes] = useState([]);

    const S3_URL = "https://inlife-new.s3.eu-west-1.amazonaws.com/houses/";
    const [properties, setProperties] = useState();
    const [loadingProperties, setLoadingProperties] = useState(true);

    const [showRecommendationModal, setShowRecommendationModal] = useState(false)
    const [recommendations, setRecommendations] = useState([])
    const [showRooms, setShowRooms] = useState("");
    const [showToLandlord, setShowToLandlord] = useState( true);
    const [isPlaying, setIsPlaying] = useState(false);

    const [bdayPickerYear, setBdayPickerYear] = useState(true);


    const accommodationTypes = ["rooms", "apartment", "residence"].map(type => ({value: type, label: toCapitalize(type)}));
    const genders = ["male", "female", "other", "ratherNotSay"].map(gender => ({value: gender, label: toCapitalize(gender)}));
    const occupations = ["student", "worker", "internship", "other"].map(oc => ({value: oc, label: toCapitalize(oc)}));

    useEffect(() => {
        (async () => {
            setRegions((await dataService.getRegions()));
        })();
        (async () => {
            setUniversities((await dataService.getUniversities()));
        })();
        (async () => {
            let _countries = await dataService.getCountries();
            setCountries(_countries);
            setDialCodes(_countries.map(c => ({value: "+" + c.dial_code, label: "+" + c.dial_code})));
        })();
        (async () => {
            setNeighborhoods((await dataService.getNeighborhoods()));
        })();
        (async () => {
            let _tenant = await tenantService.getTenantById(tenant_id);
            _tenant.birthdate = _tenant.birthdate && new Date(
                parseInt(_tenant.birthdate?.split("-")[1]),
                parseInt(_tenant.birthdate?.split("-")[0]) - 1,
            );
            setTenant(_tenant);
            setEditedTenant(_tenant);
            setShowToLandlord(_tenant.presentationVideo ? _tenant.presentationVideo.validated : true);
        })();
        (async () => {
            let {properties: _properties} = await propertyService.getProperties("", [], tenant_id)
            setProperties(_properties);
            setLoadingProperties(false);
        })();
    }, [])

    const getLocationSentence = () => {
        let sentence;
        switch (tenant.location) {
            case "none":
                sentence = " no preference on location"
                break;
            case "metro":
                sentence = " close to the " + ((tenant.metro + " line") || " subway");
                break;
            case "university":
                sentence = " near the " + (tenant.university?.split("-")[0] || "") + " university";
                break;
            case "neighborhood":
                sentence = tenant.neighborhood ? " near " + tenant.neighborhood : "";
                break;
            default:
                sentence = ", no preference on location";
                break;
        }
        return sentence;
    }

    const [countryLabels, countryOptions] = useMemo(() => {
        let countryLabels = {};
        let countryOptions = countries.map(country => {
            countryLabels[country.iso2] = t('country.' + country.iso2);
            return {
                value: country.iso2,
                label: t("country." + country.iso2),
                dial: country.dial_code
            }
        });

        return [countryLabels, countryOptions];
    }, [countries])


    const locations = useMemo(() => {
        if (!editedTenant) return [];
        let locations = (editedTenant.city === "Lisboa" || editedTenant.city === "Porto") ? ["none", "metro", "neighborhood", "university"] : ["none", "university"];
        return locations.map(loc => ({value: loc, label: loc}));
    }, [editedTenant?.city]);

    const metroLines = useMemo(() => {
        return (editedTenant?.city === "Lisboa" ?
            ["blue", "yellow", "red", "green"] : ["blue", "yellow", "red", "green", "violet", "orange", "pink"])
            .map(line => ({value: line, label: `metro_lines.${line}`}));
    }, [editedTenant?.city]);

    const universityOptions = useMemo(() => {
        return (universities[editedTenant?.city]?.map(university => ({value: university, label: university})) || []);
    }, [universities, editedTenant?.city]);

    const neighborhoodOptions = useMemo(() => {
        return (neighborhoods[editedTenant?.city]?.map(neighborhood => ({value: neighborhood, label: (neighborhood === "Other" ? "Outro" : neighborhood)})) || []);
    }, [neighborhoods, editedTenant?.city]);

    const options = useMemo(() => {
        if (!editedTenant || !editedTenant.city || !editedTenant.location) return [];
        let options;
        switch (editedTenant.location) {
            case "metro":
                options = metroLines;
                break;
            case "university":
                options = universityOptions;
                break;
            case "neighborhood":
                options = neighborhoodOptions;
                break;
            case "none":
                options = [];
                break;
            default:
                options = [];
                break;
        }
        return options;
    }, [editedTenant?.location, editedTenant?.region])

    const handleChange = (e) => {
        let name = e.target.id;
        let value = e.target.value;
        setEditedTenant((prevState) => ({...prevState, [name]: value}))
    }

    const handleDeleteAccount = () => {
        setModal({
            title: "Delete account",
            size: "md",
            message: <>Are you sure you want to <strong>delete</strong> {tenant.name}'s account?</>,
            onSubmit: async () => {
                await tenantService.deleteUser(tenant.id);
                setTenant((prevS) => ({...prevS, deleted: true}));
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Account deleted.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const handleArchiveUser = () => {
        setModal({
            title: "Archive User",
            size: "md",
            message: <>Are you sure you want to <strong>archive</strong> {tenant.name}'s account?</>,
            onSubmit: async () => {
                await tenantService.archiveUser(tenant.id);
                setTenant((prevS) => ({...prevS, archived: true}));
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Account archived.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const handleUnarchiveUser = () => {
        setModal({
            title: "Unarchive User",
            size: "md",
            message: <>Are you sure you want to <strong>unarchive</strong> {tenant.name}'s account?</>,
            onSubmit: async () => {
                await tenantService.unarchiveUser(tenant.id);
                setTenant((prevS) => ({...prevS, archived: false}));
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Account unarchived.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const activateAccount = () => {
        setModal({
            title: "Enable Account",
            size: "md",
            message: <>Are you sure you want to <strong>enable</strong> {tenant.name}'s account?</>,
            onSubmit: async () => {
                await tenantService.unDeleteUser(tenant.id);
                setTenant((prevS) => ({...prevS, deleted: false}));
                setModal(m => ({...m, hide: true}));
                cogoToast.success('Account enabled.', {
                    hideAfter: 5,
                    position: 'top-center',
                    heading: 'Success'
                })
            }
        })
    }

    const [searchKey, setSearchKey] = useState("");
    // Bind search change event with debounce
    const searchTimeout = useRef(null);
    useEffect(() => {
        //if (!searchKey) return;
        window.clearTimeout(searchTimeout.current);
        searchTimeout.current = window.setTimeout(() => {
            handleUpdateProperties(searchKey);
        }, 500);
    }, [searchKey]);


    const handleUpdateProperties = async (searchKey) => {
        if (!tenant) return;
        setLoadingProperties(true);
        let {properties: _properties} = await propertyService.getProperties(searchKey, [], tenant.id)
        setProperties(_properties);
        setLoadingProperties(false);
    }


    const handleChangeRecommendations = (property, room) => {
        let rec = property.id + "_" + room.id;
        if (recommendations.includes(rec)) {
            setRecommendations(recommendations.filter(el => el !== rec));
        } else {
            setRecommendations(prevState => ([...prevState, rec]))
        }
    }

    const handleSubmitRecommendations = async () => {
        setShowRecommendationModal(false);
        setLoading(true);
        await tenantService.recommendAds(tenant_id, recommendations)
        setLoading(false);
        cogoToast.success('Suggested listing.', {
            hideAfter: 5,
            position: 'top-center',
            heading: 'Success'
        })
    }

    const saveChanges = async () => {
        if (editedTenant.location === "university" && (!universities.hasOwnProperty(editedTenant.city) || !(universities[editedTenant.city]).includes(editedTenant.university))) {
            delete editedTenant.university;
        }
        if (editedTenant.moveIn && editedTenant.moveOut && !moment(editedTenant.moveIn).isBefore(moment(editedTenant.moveOut))) {
            cogoToast.error("Invalid input dates.", {
                hideAfter: 3,
                position: "top-center",
                heading: "Error"
            })
            return;
        }
        if (parseInt(editedTenant.budgetMin) > parseInt(editedTenant.budgetMax)) {
            cogoToast.error("Invalid budget values.", {
                hideAfter: 3,
                position: "top-center",
                heading: "Error"
            })
            return;
        }
        setLoading(true)
        let editedBirthdate = editedTenant.birthdate;
        let originalBirthdate = tenant.birthdate;
        editedTenant.birthdate = moment(editedBirthdate).format("MM-YYYY");
        tenant.birthdate = moment(originalBirthdate).format("MM-YYYY");
        let objectToSend = {};
        let differences = jsonDiff.diff(tenant, editedTenant)
        if (!differences) {
            editedTenant.birthdate = editedBirthdate;
            tenant.birthdate = originalBirthdate;
            setEditable(false);
            setLoading(false);
            return;
        }
        Object.entries(differences).map(([k, v]) => {
            if (k.includes("__added")) {
                objectToSend[k.replace("__added", "")] = v;
            } else {
                objectToSend[k] = v["__new"];
            }
        })
        objectToSend.id = tenant.id;
        await tenantService.updateUser(objectToSend);
        editedTenant.birthdate = editedBirthdate;
        tenant.birthdate = originalBirthdate;
        setTenant(editedTenant);
        setEditable(false);
        setLoading(false);
        cogoToast.success('Changes saved!', {
            hideAfter: 5,
            position: 'top-center',
            heading: 'Success'
        });
    }

    let cityOptions = useMemo(() => {
        return regions?.map(city => ({value: city, label: city})) || [];
    }, [regions]);

    let stayLength = useMemo(() => {
        if (!tenant) return;
        return moment(tenant.moveOut).diff(moment(tenant.moveIn), "month")
    }, [tenant?.moveIn, tenant?.moveOut])

    const impersonateUser = async () => {
        let token = await authService.impersonate("user", tenant.id);
        window.open(process.env.REACT_APP_STUDENT_URL + "/api/backoffice/impersonate?type=user&token=" + token, '_blank').focus();
    }

    const handleShowToLandlords = async (show) => {
        setShowToLandlord(show);
        await tenantService.validateVideo(tenant.id, tenant.presentationVideo, show);
    }

    return (
        (tenant && !loading ?
                <div className={"LandlordInformation"}>
                    <PageWrapper title={"Tenant's Page"}
                                 breadcrumbs={[
                                     {url: "tenants", title: "Tenants"},
                                     {title: tenant?.name}
                                 ]}
                                 noCard setModal={setModal}>
                        <div className="row">
                            <div className="col-12">
                                <div className="card" style={tenant.deleted ? {backgroundColor: "#f3e6e6"} : {}}>
                                    <div className="card-body">
                                        <div className="LandlordInformation_header row">
                                            <div className="col-4">
                                                <div className={"d-flex align-items-center"} style={{justifyContent: "space-between"}}>
                                                    <div className="ratingStars">
                                                        {Array.from({length: 5}, (_, i) => i + 1).map(n => {
                                                            return <i key={tenant.id + "star-" + n}
                                                                      className={`mdi mdi-star ${n <= tenant.rating ? "yellow" : "grey"}`}
                                                                      style={{fontSize: "24px"}}
                                                            />
                                                        })}
                                                    </div>
                                                    {!!tenant.ambassador && <div className={"badge badge-ambassador"}>Inlife Ambassador</div>}
                                                    {tenant.deleted && <div className={"badge badge-danger"} style={{textAlign: "center"}}>Account disabled</div>}
                                                </div>
                                                <div className={"LandlordInformation_topstats mt-3"}>
                                                    <div>
                                                        <p className="number text-dark mb-0">{tenant.stats?.reservations}</p>
                                                        <p className="label text-muted">Reservations</p>
                                                    </div>
                                                    <div>
                                                        <p className="number text-dark mb-0">{tenant.stats?.visits}</p>
                                                        <p className="label text-muted">Visits</p>
                                                    </div>
                                                    <div>
                                                        <p className="number text-dark mb-0">{tenant.stats?.offers}</p>
                                                        <p className="label text-muted">Received Offers</p>
                                                    </div>
                                                </div>
                                                <span className={"mt-1 mb-1"} style={{textDecoration: "underline"}}>Additional information:</span>
                                                <div>{editable ? <textarea className={"form-control mt-1"} id={"extraInfo"} defaultValue={tenant.extraInfo} onBlur={handleChange}/> : tenant.extraInfo || "(unfilled)"}</div>
                                            </div>
                                            <div className="col-4">
                                                <div
                                                    style={{backgroundImage: 'url(' + tenant.photo || defaultAvatar + ')'}}
                                                    className="rounded-circle avatar"/>
                                                {editable ?
                                                    <div className="LandlordInformation_header-info mb-2">
                                                        <input className="form-control form-control-sm mb-1 mt-1" type={"text"}
                                                               style={{width: "fit-content"}}
                                                               id={"name"} defaultValue={tenant.name}
                                                               onBlur={handleChange}/>
                                                        <div className={"d-flex mb-1"} style={{maxWidth: "100%"}}>
                                                            <div style={{
                                                                width: '200px', fontFamily: "Inter, Roboto, sans-serif",
                                                                fontSize: "0.75rem",
                                                                fontWeight: "400"
                                                            }} className={"mr-1"}>
                                                                <Select
                                                                    options={countryOptions}
                                                                    placeholder={"Nationality"}
                                                                    defaultValue={countryOptions.find(country => country.value === editedTenant.nationality)}
                                                                    onChange={(country) => setEditedTenant(prevState => ({...prevState, ["nationality"]: country.value}))}
                                                                />
                                                            </div>
                                                            <div style={{
                                                                minWidth: '115px', fontFamily: "Inter, Roboto, sans-serif",
                                                                fontSize: "0.75rem",
                                                                fontWeight: "400"
                                                            }} className={"mr-1"}>
                                                                <Select
                                                                    options={genders}
                                                                    placeholder={"Gender"}
                                                                    value={genders.find(gender => gender.value === editedTenant.gender)}
                                                                    onChange={(gender) => setEditedTenant(prevState => ({...prevState, ["gender"]: gender.value}))}
                                                                />
                                                            </div>
                                                            <DatePicker
                                                                className={"form-control"}
                                                                autoComplete="bday"
                                                                onBlur={e => {
                                                                    let inputDate = e.target.value;
                                                                    if (/^(0[1-9]|1[012])\/([0-9]{4}|[0-9]{2})$/g.test(inputDate)) {
                                                                        let [mo, year] = inputDate?.split('/');
                                                                        if (year.length === 2) {
                                                                            year = (year > 70 ? "19" : "20") + year;
                                                                        }
                                                                        setEditedTenant(b => ({
                                                                            ...b,
                                                                            birthdate: new Date(year, parseInt(mo) - 1)
                                                                        }));
                                                                    }
                                                                }}
                                                                onChangeRaw={e => {
                                                                    if (e.target.value && !e.nativeEvent.inputType.startsWith("delete")) {
                                                                        if (e.target.value.length === 2) {
                                                                            if (e.target.value.slice(-1) === "/") {
                                                                                e.target.value = "0" + e.target.value;
                                                                            } else {
                                                                                e.target.value += "/";
                                                                            }
                                                                        } else if (e.target.value.length === 3 && e.target.value.slice(-1) === "/") {
                                                                            e.target.value = e.target.value.slice(0, 2);
                                                                        }
                                                                    }
                                                                }}
                                                                onSelect={date => {
                                                                    setBdayPickerYear(y => !y);
                                                                    setEditedTenant(b => ({...b, birthdate: date}));
                                                                }}
                                                                shouldCloseOnSelect={!bdayPickerYear}
                                                                selected={editedTenant.birthdate} dateFormat="MM/yyyy"
                                                                showYearPicker={bdayPickerYear}
                                                                showMonthYearPicker={!bdayPickerYear}
                                                                placeholderText={"Birthdate"}
                                                            />
                                                        </div>
                                                        <div className={"d-flex mb-1"} style={{maxWidth: "100%"}}>
                                                            <div style={{
                                                                width: '200px', fontFamily: "Inter, Roboto, sans-serif",
                                                                fontSize: "0.75rem",
                                                                fontWeight: "400"
                                                            }} className={"mr-1"}>
                                                                <Select
                                                                    options={occupations}
                                                                    placeholder={"Occupation"}
                                                                    defaultValue={occupations.find(occupation => occupation.value === editedTenant.occupation)}
                                                                    onChange={(occupation) => setEditedTenant(prevState => ({...prevState, ["occupation"]: occupation.value}))}
                                                                />
                                                            </div>
                                                            <div className={"d-flex flex-column"}>
                                                                {editedTenant.occupation === "student" ?
                                                                    <div style={{
                                                                        width: '200px', fontFamily: "Inter, Roboto, sans-serif",
                                                                        fontSize: "0.75rem",
                                                                        fontWeight: "400"
                                                                    }}>
                                                                        <Select
                                                                            options={universityOptions}
                                                                            placeholder={"Select a university"}
                                                                            defaultValue={universityOptions.find(uni => uni.value === editedTenant.university)}
                                                                            onChange={(uni) => setEditedTenant(prevState => ({...prevState, ["university"]: uni.value}))}
                                                                        />
                                                                    </div> :
                                                                    <textarea className={"form-control"} maxLength={100} id={"occupation_detail"} onBlur={handleChange} placeholder={"Details"}/>
                                                                }
                                                                {editedTenant.occupation === "student" && !editedTenant.city && <span className={"text text-danger"} style={{fontSize: "12px"}}>Select the region</span>}
                                                            </div>
                                                        </div>
                                                        <input className={"form-control mb-1"} style={{width: "fit-content"}} type={"text"} defaultValue={tenant.email} id={"email"} readOnly/>
                                                        <div className={"d-flex align-items-center"}>
                                                            <div style={{
                                                                width: '80px', fontFamily: "Inter, Roboto, sans-serif",
                                                                fontSize: "0.75rem",
                                                                fontWeight: "400"
                                                            }} className={"mr-2"}><Select
                                                                options={dialCodes}
                                                                placeholder={"+351"}
                                                                defaultValue={dialCodes.find(uni => uni.value === editedTenant.dialCode)}
                                                                onChange={(dial) => setEditedTenant(prevState => ({...prevState, ["dialCode"]: dial.value}))}
                                                            />
                                                            </div>
                                                            <input className={"form-control mb-2"} style={{width: "fit-content"}} type={"text"} defaultValue={tenant.phone} placeholder={"Phone"} id={"phone"} onBlur={handleChange}/>
                                                        </div>
                                                    </div>
                                                    : <div className="LandlordInformation_header-info mb-2">
                                                        <h3 className="title text-capitalize">{tenant.name}</h3>
                                                        <div
                                                            className="mb-0 d-flex align-items-center no-wrap">{(tenant.nationality ? t("country." + tenant.nationality) : "n.d") + " - " + (toCapitalize(tenant.gender) || "N/A") + " - "} {tenant.birthdate ? tenantService.getAge(tenant.birthdate) + " years" : "n.d"} </div>
                                                        <div className={"mb-0 d-flex align-items-center no-wrap"}>{toCapitalize(tenant.occupation)} {(tenant.occupation === "student" && tenant.university) ? " at" + " " + tenant.university?.split(" - ")[0] : tenant.occupation_detail}</div>
                                                        <p className="mb-0 d-flex align-items-center no-wrap">
                                                            <i className="mdi mdi-email-outline mr-2"/>
                                                            {tenant.email}
                                                        </p>
                                                        <p className={"d-flex align-items-center no-wrap"}>
                                                            <i className="mdi mdi-phone text-info mr-2"/>
                                                            {(tenant.dialCode ? tenant.dialCode + " " : "") + tenant.phone}
                                                        </p>
                                                    </div>}
                                            </div>
                                            <div className="col-4 d-flex justify-content-end p-0">
                                                {editable &&
                                                    <button style={{maxHeight: "35px"}} className={"btn btn-outline-danger btn-true-sm mr-2"}
                                                            onClick={() => {
                                                                setEditedTenant(tenant);
                                                                setEditable(false);
                                                            }}>Cancel</button>}
                                                <button
                                                    style={{maxHeight: "35px"}}
                                                    className={`btn btn-outline-${editable ? "success" : "primary"} btn-true-sm mr-2`}
                                                    onClick={editable ? saveChanges : () => setEditable(true)}>{editable ? "Save Changes" : "Edit"}</button>
                                                {!editable && !tenant.deleted && <button className="btn btn-danger btn-true-sm mr-2" style={{maxHeight: "35px"}} onClick={handleDeleteAccount}>Delete Account</button>}
                                                {!editable && tenant.deleted && <button className="btn btn-success btn-true-sm mr-2" style={{maxHeight: "35px"}} onClick={activateAccount}>Enable Account</button>}
                                                {!editable && !tenant.archived && user?.permissions?.includes("tenants_archive") && <button className="btn btn-warning btn-true-sm mr-2" style={{maxHeight: "35px"}} onClick={handleArchiveUser}>Archive Account</button>}
                                                {!editable && tenant.archived && user?.permissions?.includes("tenants_unarchive") && <button className="btn btn-success btn-true-sm mr-2" style={{maxHeight: "35px"}} onClick={handleUnarchiveUser}>Unarchive Account</button>}
                                                {!editable && <button className="btn btn-primary btn-true-sm" onClick={impersonateUser} style={{maxHeight: "35px"}}>Login as Tenant</button>}
                                            </div>
                                        </div>
                                        <div className={"LandlordInformation_body row"}>
                                            <div className={"col-8 card flex-row"} style={tenant.deleted ? {backgroundColor: "#f3e8e8"} : {}}>
                                                <div className={"card-body"}>
                                                    <div className={"mb-2"}>Preferences</div>
                                                    <div className="align-items-center d-flex mb-0">
                                                        <i className={"mdi mdi-home-map-marker icon-md-1 mr-2 text-muted"}/>
                                                        {editable ?
                                                            <div className={"d-flex"}>
                                                                <div style={{
                                                                    minWidth: '115px', fontFamily: "Inter, Roboto, sans-serif",
                                                                    fontSize: "0.75rem",
                                                                    fontWeight: "400"
                                                                }} className={"mr-1"}>
                                                                    <Select
                                                                        placeholder={"Choose region"}
                                                                        options={cityOptions}
                                                                        defaultValue={cityOptions.find(region => region.value === editedTenant.city)}
                                                                        onChange={(city) => setEditedTenant(prevState => ({...prevState, ["city"]: city.value}))}
                                                                    />
                                                                </div>
                                                                <div style={{
                                                                    minWidth: '115px', fontFamily: "Inter, Roboto, sans-serif",
                                                                    fontSize: "0.75rem",
                                                                    fontWeight: "400"
                                                                }} className={"mr-1"}>
                                                                    <Select
                                                                        placeholder={"Nearby:"}
                                                                        options={locations}
                                                                        value={locations.find(loc => loc.value === editedTenant.location)}
                                                                        onChange={(loc) => setEditedTenant(prevState => ({...prevState, ["location"]: loc.value}))}
                                                                    />
                                                                </div>
                                                                <div style={{
                                                                    minWidth: '115px', fontFamily: "Inter, Roboto, sans-serif",
                                                                    fontSize: "0.75rem",
                                                                    fontWeight: "400"
                                                                }} className={"mr-1"}>
                                                                    <Select
                                                                        options={options}
                                                                        defaultValue={options.find((val) => val.value === editedTenant[editedTenant.location])}
                                                                        onChange={(option) => {
                                                                            if (editedTenant.location !== "none") setEditedTenant(prevState => ({...prevState, [editedTenant.location]: option.value}))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : <span>{tenant.city ? (tenant.city + (tenant.location ? getLocationSentence(tenant.location) : "")) : (tenant.location ? getLocationSentence(tenant.location) : "n.d")}</span>}
                                                    </div>
                                                    <div className="align-items-center d-flex mb-0">
                                                        <i className="mdi mdi-calendar icon-md-1 mr-2 text-muted"/>
                                                        <strong className={"mr-1"}>Date of check-in: </strong>{editable ? <DatePicker className={"form-control"} dateFormat={"dd/MM/yyyy"} selected={editedTenant.moveIn && new Date(editedTenant.moveIn)} onChange={(e) => setEditedTenant(prevState => ({...prevState, ["moveIn"]: e.toISOString().substring(0, 10)}))
                                                    }/> : (tenant.moveIn ? moment(tenant.moveIn).format("DD/MM/YYYY") : "")}
                                                    </div>
                                                    <div className="align-items-center d-flex mb-0">
                                                        <i className="mdi mdi-calendar icon-md-1 mr-2 text-muted"/>
                                                        <strong className={"mr-1"}>Date of check-out: </strong>{editable ? <DatePicker className={"form-control"} dateFormat={"dd/MM/yyyy"} selected={editedTenant.moveOut && new Date(editedTenant.moveOut)} onChange={(e) => setEditedTenant(prevState => ({...prevState, ["moveOut"]: e.toISOString().substring(0, 10)}))}/> : (tenant.moveIn ? moment(tenant.moveOut).format("DD/MM/YYYY") : "")}
                                                    </div>
                                                    {stayLength &&
                                                        <div className={""}>
                                                            <i className={"mdi mdi-timetable icon-md-1 mr-2 text-muted"}/>
                                                            {stayLength + (stayLength === 1 ? " month" : " months")}
                                                        </div>}
                                                    <div className="align-items-center d-flex mb-1">
                                                        <i className="mdi mdi-office-building icon-md-1 mr-2 text-muted"/>
                                                        {editable && <>
                                                            <div style={{
                                                                minWidth: '115px', fontFamily: "Inter, Roboto, sans-serif",
                                                                fontSize: "0.75rem",
                                                                fontWeight: "400"
                                                            }} className={"mr-1"}>
                                                                <Select defaultValue={accommodationTypes.find(type => type.value === editedTenant.accommodation)} placeholder={"Type"} options={accommodationTypes} onChange={(value) => {
                                                                    setEditedTenant((prev) => ({...prev, "accommodation": value.value}))
                                                                }}/>
                                                            </div>
                                                            <label htmlFor={"numRooms"} className={"mr-1"}>Nº Rooms</label>
                                                            <input className={"form-control mr-1"} style={{width: "fit-content"}} id={"numRooms"} type={"number"} min={0} defaultValue={editedTenant.numRooms} onChange={handleChange}/>
                                                            <label htmlFor={"numTenants"} className={"mr-1"}>Nº Tenants</label>
                                                            <input className={"form-control"} style={{width: "fit-content"}} id={"numTenants"} type={"number"} min={0} defaultValue={editedTenant.numTenants} onChange={handleChange}/>
                                                        </>}
                                                        {tenant.accommodation && tenant.numRooms && tenant.numTenants && !editable && <span>{tenant.accommodation !== "rooms" ? toCapitalize(tenant.accommodation) : ""} {tenant.accommodation === "apartment" && " with "} {tenant.accommodation !== "residence" ? (tenant.numRooms === 1 ? "1 room" : tenant.numRooms + " rooms") : ""} for {tenant.numTenants === 1 ? "1 person" : tenant.numTenants + " people"}</span>}
                                                    </div>
                                                    <div className="align-items-center d-flex mb-0">
                                                        <i className=" mdi mdi-cash-multiple icon-md-1 mr-2 text-muted"/>
                                                        {editable ? <>
                                                                <input className={"form-control"} type={"number"} defaultValue={editedTenant.budgetMin} id={"budgetMin"} placeholder={"Input a value"} onChange={handleChange}/>
                                                                <span className={"ml-1 mr-1"}>-</span>
                                                                <input className={"form-control"} type={"number"} defaultValue={editedTenant.budgetMax} id={"budgetMax"} placeholder={"Input a value"} onChange={handleChange}/>
                                                            </> :
                                                            <span>{formatCurrency([tenant.budgetMin, tenant.budgetMax])}</span>}
                                                    </div>
                                                    <button className={"btn btn-secondary mt-2"} onClick={() => setShowRecommendationModal(true)}>Suggest Ads</button>
                                                </div>
                                                <div className={"card-body"}>
                                                    <div className={"mb-2"}>Data</div>
                                                    <div className={"d-flex flex-column"}>
                                                        <span>Account Creation: {moment(tenant.registrationDate).format("DD/MM/YYYY")}</span>
                                                        <span>Latest login: {moment(tenant.lastLogin).format("DD/MM/YY HH:mm")}</span>
                                                        <span>Reservation Requests: {tenant.stats.reservations}</span>
                                                        <span>Pending Reservation Requests (unpaid or unaccepted): {tenant.stats.pendingReservations}</span>
                                                        <span>Reservations Effected:{tenant.stats.paidReservations}</span>
                                                        <span>Visit requests: {tenant.stats.visits}</span>
                                                        <span>Scheduled visits: {tenant.stats.selectedVisits}</span>
                                                        <span>Visits Effected: {tenant.stats.completedVisits}</span>
                                                        <span>Active Offers: {tenant.stats.activeOffers}</span>
                                                        <span>Rejected/expired offers: {tenant.stats.rejectedOffers}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal
                                                size={"lg"}
                                                show={showRecommendationModal}
                                                onHide={() => setShowRecommendationModal(false)}
                                                aria-labelledby="modal-dialog-wrapper"
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Suggest Listing</Modal.Title>
                                                </Modal.Header>

                                                <Modal.Body>
                                                    <div className={"d-flex flex-column"}>
                                                        <div className={"d-flex justify-content-between align-items-center mb-2"}>
                                                            <div>Choose from the list <strong>listings to suggest</strong> to {tenant.name}</div>
                                                            <div className={"search-input"}><input type={"text"} onChange={(e) => setSearchKey(e.target.value)} className={"form-control"} placeholder={"Search ad"}/><i className={"fa fa-search"}/></div>
                                                        </div>
                                                        <div>
                                                            {!loadingProperties && properties ? Object.values(properties)?.map((property) => {
                                                                    if (property === "success" || property.rooms?.filter(r => !r.disabled).length === 0) return;
                                                                    return (<>
                                                                        <div className={`ResultCard ${showRooms === property.id ? "" : "mb-3"}`} key={property.id} onClick={() => {
                                                                            if (property.rooms.length > 0) setShowRooms(showRooms === property.id ? "" : property.id);
                                                                        }}>
                                                                            <div className={"ResultCard_property-info"}>
                                                                                <div>{property.address} {property.floor} ({property.internal_name}) {property.people && <span>(Up to {property.people} people)</span>}</div>
                                                                                <span>Landlord:</span>
                                                                                <span>{property.landlord_name || "N/A"}</span>
                                                                                <span>{property.landlord_email || "N/A"}</span>
                                                                                <span>{property.landlord_phone || "N/A"}</span>
                                                                            </div>
                                                                            <div className={"ResultCard_property-rent"}>
                                                                                <span>Rent: {propertyService.getRentsInterval(property)}</span>
                                                                                <span>Bills{property.billsIncluded ? " " : " not "}included</span>
                                                                                {property.onlyFemales && <span>Only women</span>}
                                                                                {property.onlyMales && <span>Only men</span>}
                                                                            </div>
                                                                            {property.rooms.filter(r => !r.disabled).length > 0 && <div className={"ResultCard_property-extra"}><i className={`mdi mdi-chevron-${showRooms === property.id ? "up" : "down"}`}/></div>}
                                                                        </div>
                                                                        {(showRooms === property.id && property.rooms.length > 0) &&
                                                                            <div>
                                                                                {property.rooms.filter(r => !r.disabled).map((room, index) => {
                                                                                    if (property.accommodation === "apartment" && index !== 0) return;
                                                                                    return (<div className={"ResultRoomCard" + (index === property.rooms.length - 1 ? " mb-3" : "")} key={"room_" + room.id}>
                                                                                        <div className={"ResultRoomCard-photo"} style={{backgroundImage: `url(${property.rooms[index].photos[0] ? (property.integration_id ? property.rooms[index].photos[0] : (S3_URL + property.id + property.rooms[index].photos[0])) : defaultPhoto})`}}/>
                                                                                        <div className={"ResultRoomCard_content"}>
                                                                                            <div>
                                                                                                <span><strong>{room.internalName}</strong></span>
                                                                                                <span>For {parseInt(room.numPeople) === 1 ? "one person" : room.numPeople + " people"}</span>
                                                                                                <span>Minimum stay: {room.periodStayMin || "N/A"}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span>Rent: {room.minRent === room.maxRent ? room.minRent + '€' : room.minRent + " - " + room.maxRent + '€'}</span>
                                                                                                <span>Available from {moment(room.firstAvailability).format("DD/MM/YYYY")}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label>Select room</label>
                                                                                                <input className={"form-control"} type={"checkbox"} checked={recommendations.includes(property.id + "_" + room.id)} disabled={recommendations.length === 10 && !recommendations.includes(property.id + "_" + room.id)} onClick={() => handleChangeRecommendations(property, room)}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>)
                                                                                })
                                                                                }
                                                                            </div>}
                                                                    </>)
                                                                })
                                                                : <div className="dot-opacity-loader">
                                                                    <span/>
                                                                    <span/>
                                                                    <span/>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Modal.Body>

                                                <Modal.Footer>
                                                    {recommendations.length >= 10 && <span className={"text-danger"}>You can only recommend up to 10 listings</span>}
                                                    <Button variant="light m-2" onClick={() => setShowRecommendationModal(false)}>Cancel</Button>
                                                    <Button variant="success m-2" onClick={handleSubmitRecommendations}>Confirm</Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <div className={"col-4"}>

                                            </div>
                                        </div>
                                        {tenant.presentationVideo && <div className={"col-8 tenant_video"}>
                                            <div className={"d-flex video_presentation_title"}>
                                                <p className={"d-flex"}>Video presentation</p>
                                                <div className={"d-flex align-items-center ml-3"}>
                                                    <div>Show to landlords</div>
                                                    <label className={"switch"} style={{marginBottom: "unset"}}>
                                                        <input type={"checkbox"} className={"ml-2"}
                                                               checked={showToLandlord}
                                                               onChange={() => handleShowToLandlords(!showToLandlord)}/>
                                                        <span className="slider round"/>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={"d-flex"}>
                                                <div className={"react_player"}>
                                                    <ReactPlayer
                                                        url={tenant.presentationVideo.url}
                                                        playing={isPlaying}
                                                        controls={true}
                                                        light={tenant.presentationVideo.thumbnail}
                                                        width={"498px"}
                                                        height={"273.53px"}
                                                        playIcon={<img alt={"play icon"} src={play}
                                                                       onClick={() => setIsPlaying(!isPlaying)}/>}
                                                    />
                                                </div>
                                            </div>
                                            {!tenant.presentationVideo.processed && <p className={"d-flex"} style={{color: "#DEDEDE", marginTop: "5px"}}>Still processing...</p>}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PageWrapper>
                    <div className="LandlordInformation_tabs row mt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <Tabs mountOnEnter defaultActiveKey="home" id="uncontrolled-tab-example">
                                        <Tab eventKey="reservations" title="Reservations">
                                            <Reservations overrideFetch={() => {
                                                return tenantService.getTenantReservations(tenant_id)
                                            }} inline customDataTable={"mt-1"}/>
                                        </Tab>
                                        <Tab eventKey="visits" title="Visits">
                                            <Visits overrideFetch={() => {
                                                return tenantService.getTenantVisits(tenant_id)
                                            }} inline customDataTable={"mt-1"}/>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Spinner inline/>
                            </div>
                        </div>
                    </div>
                </div>
        )
    )
}


export default TenantDetails;
